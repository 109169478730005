import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-tyler-texas.png'
import image0 from "../../images/cities/scale-model-of-caldwell-zoo-in-tyler-texas.png"
import image1 from "../../images/cities/scale-model-of-goodman-legrand-house-&-museum-in-tyler-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Tyler'
            state='Texas'
            image={image}
            lat='32.3512601'
            lon='-95.30106239999998'
            attractions={ [{"name": "Caldwell Zoo", "vicinity": "2203 W Martin Luther King Jr Blvd, Tyler", "types": ["zoo", "point_of_interest", "establishment"], "lat": 32.36992540000001, "lng": -95.32556390000002}, {"name": "Goodman-LeGrand House & Museum", "vicinity": "624 N Broadway Ave, Tyler", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.3567677, "lng": -95.30152629999998}] }
            attractionImages={ {"Caldwell Zoo":image0,"Goodman-LeGrand House & Museum":image1,} }
       />);
  }
}